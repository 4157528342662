import {flattenObject} from '@fo/shared-helpers/objects';
import removeEmptyValuesFromObject from '@fo/shared-helpers/removeEmptyValuesFromObject';
import {ObjectOfAny} from '@fo/shared-types';
import Analytics from '@fo/shared-data/apis/Analytics';

export enum FoTrackEvent {
  quoteFormStepOne = 'quote_form_step_one',
  quoteFormStepTwo = 'quote_form_step_two',
  quoteFormStepThree = 'quote_form_step_three',
}

export interface IDispatchEvent {
  name: FoTrackEvent;
  values?: ObjectOfAny;
}

export interface ITrackEvent {
  name: string;
  values?: string;
}

export const track = ({name, values}: ITrackEvent): Promise<void> => {
  try {
    Analytics.track({
      name,
      time: new Date().toISOString(),
      values,
    });
    return Promise.resolve();
  } catch (error) {
    return Promise.reject();
  }
};

export const dispatchFoEvent = ({name, values}: IDispatchEvent): Promise<void> => {
  const cleanedValues = values ? removeEmptyValuesFromObject(values) : {};
  const {password, ...other} = flattenObject(cleanedValues); // always remove password

  const payload: ITrackEvent = {
    name: name.toString(),
  };

  if (Object.keys(other).length) {
    payload.values = JSON.stringify(other);
  }

  return track(payload);
};
