import {AxiosResponse} from 'axios';
import SystemOfRecordService, {SOREndpoints} from './SystemOfRecordService';

export interface ITrackPayload {
  name: string;
  time: string;
  values?: string;
}

const track = async (body: ITrackPayload): Promise<AxiosResponse> =>
  SystemOfRecordService.post(SOREndpoints.TRACK_EVENT, body);

const Analytics = {
  track,
};

export default Analytics;
