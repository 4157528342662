import mixpanel from 'mixpanel-browser';

import {flattenObject} from '@fo/shared-helpers/objects';
import removeEmptyValuesFromObject from '@fo/shared-helpers/removeEmptyValuesFromObject';
import {ObjectOfAny, User} from '@fo/shared-types';

import {getCookieTrackingConsent} from './getCookieTrackingConsent';

export enum MixpanelTrackEvent {
  additionalQuestionsFPSP = 'additional_questions_fpsp',
  additionalQuestionsLoad = 'additional_questions_load',
  additionalQuestionsSkipBottom = 'additional_questions_skip_bottom',
  additionalQuestionsSkipTop = 'additional_questions_skip_top',
  additionalQuestionsSubmit = 'additional_questions_submit',
  advisorCopyAffidValue = 'advisor_copy_affid_value',
  advisorCreateAccount = 'advisor_create_account',
  advisorFOContactClient = 'advisor_fo_contact_client',
  advisorGetStarted = 'advisor_get_started',
  advisorReferModalOpen = 'advisor_refer_modal_open',
  advisorSelfContactClient = 'advisor_self_contact_client',
  advisorShareApplicationWithClientButton = 'advisor_share_application_with_client_button',
  advisorShareApplicationWithClientModalLoad = 'advisor_share_application_with_client_modal_load',
  advisorShareApplicationWithClientSubmit = 'advisor_share_application_with_client_submit',
  advisorViewClientApplication = 'advisor_view_client_application',
  appliedForModalContinue = 'applied_for_modal_continue',
  appliedForModalReturn = 'applied_for_modal_return',
  assetTermLength = 'finance_term_length',
  bcaCta = 'business_loan_calculator_cta',
  bcaProductButtonInfo = 'bca_product_button_info',
  bcaProductButtonInfoExternal = 'bca_product_button_info_external',
  blogSubscribedToNewsletter = 'blog_subscribed_to_newsletter',
  brsAdvisorGetStarted = 'brs_advisor_get_started',
  businessServicesCta = 'business_services_cta',
  confirmAccount = 'confirm_account',
  confirmEmailAddress = 'confirm_email_address',
  confirmOwnershipOnLoad = 'confirm_ownership_on_load',
  continueWithLender = 'continue_with_lender',
  createAccount = 'create_account',
  createAccountModalCreateAdvisorClicked = 'create_account_modal_create_advisor_clicked',
  createAccountModalCreateCustomerClicked = 'create_account_modal_create_customer_clicked',
  createAccountModalShown = 'create_account_modal_shown',
  customerCreateAccount = 'customer_create_account',
  dialogueDismiss = 'dialogue_dismiss',
  directorsFormAddAddress = 'directors_form_add_address',
  directorsFormAddDirector = 'directors_form_add_director',
  directorsFormAddDismissDialogue = 'directors_form_dismiss_dialogue',
  directorsFormDeleteAddress = 'directors_form_delete_address',
  directorsFormDeleteDirector = 'directors_form_delete_director',
  directorsFormNavigateToNextPage = 'directors_form_navigate_to_next_page',
  directorsFormOnLoad = 'directors_form_on_load',
  directorsFormPrefill = 'directors_form_prefill',
  directorsFormSaveAddress = 'directors_form_save_address',
  directorsFormSaveDirector = 'directors_form_save_director',
  directorsFormUpdateAddress = 'directors_form_update_address',
  directorsFormUpdateDirector = 'directors_form_update_director',
  directorsFormUpsertDirector = 'directors_form_upsert_director',
  directorsFormUpsertSubmit = 'directors_form_upsert_submit',
  docUploadDelete = 'doc_upload_delete',
  docUploadPostFailure = 'doc_upload_post_failure',
  docUploadPostSuccess = 'doc_upload_post_success',
  fiveHundred = '500_error',
  forgotPassword = 'forgot_password',
  fpspOnLoad = 'fpsp_on_load',
  fpspRefresh = 'fpsp_refresh',
  frontEndError = 'front_end_error',
  getStarted = 'get_started',
  greenFinanceCommitmentToEsgCTA = 'green_finance_commitment_to_esg_cta',
  greenFinanceForLendersCTA = 'green_finance_for_lenders_cta',
  greenFinanceForSMEsCTA = 'green_finance_for_smes_cta',
  greenFinanceHeroCTA = 'green_finance_hero_cta',
  greenFinanceRelatedArticlesCTA = 'green_finance_related_articles_cta',
  grossAssetCost = 'gross_asset_cost',
  liteQuoteFormRequestedAmount = 'lite_quote_form_requested_amount',
  liteQuoteFormSubmit = 'lite_quote_form_submit',
  liteQuoteFormSubmitSuccess = 'lite_quote_form_submit_success',
  login = 'login',
  loginInPageOnLoad = 'login_in_page_on_load',
  modalRequestedAmountVsTurnoverOnLoad = 'modal_requested_amount_vs_turnover_on_load',
  modalRequestedAmountVsTurnoverSubmitted = 'modal_requested_amount_vs_turnover_submitted',
  navigationAlternateJourneyLinkClicked = 'navigation_alternate_journey_link_clicked',
  navigationCardLinkClicked = 'navigation_card_link_clicked',
  navigationLinkClicked = 'navigation_link_clicked',
  navigationMainCtaClicked = 'navigation_main_cta_clicked',
  netAssetCost = 'net_asset_cost',
  newQuote = 'start_new_quote',
  numberOfDirectors = 'number_of_directors',
  offerExplore = 'offer_explore',
  offerProceed = 'offer_procceed',
  openAccountingButton = 'open_accounting_button',
  openAccountingEcommerceButton = 'open_accounting_ecommerce_button',
  quoteApply = 'quote_apply',
  quoteExplore = 'quote_explore',
  quoteFormDocUploadNext = 'quote_form_doc_upload_next',
  quoteFormDocUploadPrevious = 'quote_form_doc_upload_previous',
  quoteFormFinancePurpose = 'quote_form_finance_purpose',
  quoteFormAssetType = 'quote_form_asset_type',
  quoteFormOnLoad = 'quote_form_on_load',
  quoteFormOpenAccountingFailure = 'quote_form_open_accounting_failure',
  quoteFormOpenAccountingLoad = 'quote_form_open_accounting_load',
  quoteFormOpenBanking = 'quote_form_open_banking',
  quoteFormOpenBankingButtonLoaded = 'quote-form-open-banking-button-loaded',
  quoteFormOpenBankingFailure = 'quote_form_open_banking_failure',
  quoteFormOpenBankingLoadPage = 'quote_form_open_banking_load_page',
  quoteFormOpenBankingManualUpload = 'quote_form_open_banking_manual_upload',
  quoteFormRequestedAmount = 'quote_form_requested_amount',
  quoteFormStepFour = 'quote_form_step_four',
  quoteFormStepFourPrevious = 'quote_form_step_four_previous',
  quoteFormStepFourSkippedToFPSP = 'clicked_Skip_Step4',
  quoteFormStepOne = 'quote_form_step_one',
  quoteFormStepThree = 'quote_form_step_three',
  quoteFormStepTwo = 'quote_form_step_two',
  quoteProceed = 'quote_proceed',
  redirectToTide = 'redirect_to_tide',
  resetPassword = 'reset_password',
  signupModalShown = 'signup_modal_shown',
  signupModalSubmitted = 'signup_modal_submitted',
  startUpLoansProceed = 'start_up_loans_proceed',
  supplierReferral = 'supplier_exit',
  supplierReferralPage = 'supplier_info',
  supplierRelatedArticlesCTA = 'supplier_related_articles_cta',
  test = 'test_event_for_testing',
  totalNumberOfAssets = 'total_number_of_assets',
  netAssetsWithinBusiness = 'net_assets_within_business',
  userLogin = 'credentials_login',
  userLogout = 'credentials_logout',
  userMenuClickAccountDetails = 'user_menu_click_account_details',
  userMenuClickDashboard = 'user_menu_click_dashboard',
  userSignup = 'credentials_signup',
  waitForContinuationUrlFailed = 'waiting_for_continuation_url_Failed',
  waitForContinuationUrlStarted = 'waiting_for_continuation_url_started',
  waitForContinuationUrlSuccess = 'waiting_for_continuation_url_success',
  workWithUs = 'work_with_us',
}

export interface ITrackEvent {
  name: MixpanelTrackEvent;
  values?: ObjectOfAny;
}

const isMixpanelInitialised = (): boolean => {
  try {
    mixpanel.get_config('api_host');

    return true;
  } catch {
    return false;
  }
};

const initMixpanel = () => {
  if (isMixpanelInitialised()) {
    return;
  }

  const config = {
    api_host: 'https://api-eu.mixpanel.com',
  };

  if (!process.env.MIXPANEL_API_TOKEN) {
    throw new Error('Mixpanel API token not set');
  }

  mixpanel.init(process.env.MIXPANEL_API_TOKEN, config);
};

export const enqueueMixpanelEvent = (event: () => void): Promise<void> =>
  getCookieTrackingConsent().then((consent) => {
    if (consent) {
      initMixpanel();
      event();
    }
  });
export const dispatchMixpanelEvent = ({name, values}: ITrackEvent): Promise<void> => {
  const cleanedValues = values ? removeEmptyValuesFromObject(values) : {};
  const {password, ...other} = flattenObject(cleanedValues); // always remove password

  return enqueueMixpanelEvent(() => {
    mixpanel.track(name.toString(), {...other});
  });
};

export const dispatchMixpanelSuperEvent = (user: Partial<User>): Promise<void> =>
  enqueueMixpanelEvent(() =>
    mixpanel.register({
      env: process.env.STAGE,
      'userProfile.email': user.email,
      'userProfile.first_name': user.first_name,
      'userProfile.last_name': user.last_name,
    })
  );
