import {ObjectOfAny, ArrayOfAny} from '@fo/shared-types';

export interface Accumulation {
  [key: string]: string | number | boolean | undefined | null | ObjectOfAny | ArrayOfAny;
}

interface CleanedAccumulation {
  [key: string]: NonNullable<Accumulation[0]>;
}

const removeEmptyValuesFromObject = (values: Accumulation): CleanedAccumulation =>
  Object.keys(values).reduce((acc: CleanedAccumulation, key) => {
    const value = values[key];
    // value field must be at least existing to get checked
    // null values are being cut off here as well because as objects they would be true when typeof === 'object' below
    // Not using just if(fieldValue) here because it would block boolean false and number 0 as well.
    if (value === undefined || value === null) {
      return acc;
    }
    // First of all, if it is an array of values, we need to iterate through each entry and clean them up
    if (Array.isArray(value)) {
      acc[key] = (value as ArrayOfAny)
        // Go through each entry, not worrying if it's empty or not.
        .map((valueArrayEntry) => {
          if (typeof valueArrayEntry === 'object') {
            // makes sure the nested fields also get checked and properly cleaned
            return removeEmptyValuesFromObject(valueArrayEntry);
          }
          // adds only empty fields containing values
          if (valueArrayEntry.toString() !== '') {
            return valueArrayEntry;
          }
          // none of the above, entry should be marked for elimination
          return undefined;
        })
        // filter out all that is empty
        .filter((valueArrayEntry) => {
          if (valueArrayEntry === undefined || valueArrayEntry === null) {
            return false;
          }
          if (
            !Array.isArray(valueArrayEntry) &&
            typeof valueArrayEntry === 'object' &&
            !Object.keys(valueArrayEntry).length
          ) {
            return false;
          }
          return true;
        });
    }
    // from here on we take care only of single entries (not an array)
    else if (typeof value === 'object') {
      // makes sure the nested fields also get checked and properly cleaned
      acc[key] = removeEmptyValuesFromObject(value);
      // and if all fields were cleaned, resulting in an empty object...
      if (!Object.keys(acc[key] as ObjectOfAny).length) {
        // ...just remove it completely. so we don't have something like address: {}
        delete acc[key];
      }
    }
    // adds only empty fields containing values
    else if (value.toString() !== '') {
      acc[key] = value;
    }
    return acc;
  }, {});

export default removeEmptyValuesFromObject;
