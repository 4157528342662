export enum HotjarTrackEvent {
  hotjarTest = 'hotjar-test',
  proceedWithQuote = 'proceed_with_quote',
  quoteFormFinancePurpose = 'quote_form_finance_purpose',
  quoteFormLegalStatus = 'quote_form_legal_status',
  quoteFormOpenBanking = 'quote_form_open_banking',
}

export const dispatchHotjarEvent = (eventName: HotjarTrackEvent | string): void => {
  if (window.hj) {
    window.hj('event', eventName);
  }
};

export const dispatchHotjarAttribute = (userId?: string, values?: ObjectOfAny): void => {
  if (window.hj) {
    window.hj('identify', userId, values);
  }
};
