import {ObjectOfAny} from '@fo/shared-types';

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = (item: unknown): boolean => !!(item && typeof item === 'object' && !Array.isArray(item));

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */

export const getObjectKeys = Object.keys as <ObjectType>(object: ObjectType) => Extract<keyof ObjectType, string>[];

export const flattenObject = (object: ObjectOfAny): ObjectOfAny =>
  Object.assign(
    {},
    ...(function _flatten(objectBit: ObjectOfAny, path = ''): ObjectOfAny[] {
      return ([] as ObjectOfAny[]).concat(
        ...Object.keys(objectBit).map((key) => {
          const flattenedKey =
            typeof objectBit[key] === 'object'
              ? _flatten(objectBit[key], `${path}.${key}`)
              : {[`${path}.${key}`.replace(/^./, '')]: objectBit[key]};
          return flattenedKey;
        })
      );
    })(object)
  );
