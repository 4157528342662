import {useEffect, useState} from 'react';
import {trackEvent, TrackEvent} from '@fo/shared-analytics/tracking/tracking';

function TrackEventOnLoad({eventType, name, values}: TrackEvent): null {
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      trackEvent({
        eventType,
        name,
        values,
      });
    }
  }, []);

  return null;
}

export default TrackEventOnLoad;
