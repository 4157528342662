export const getCookieTrackingConsent = (): Promise<boolean> =>
  new Promise((resolve) => {
    if (typeof window === 'undefined' || !window.CookieFirst) {
      resolve(false);
      return;
    }

    const {consent} = window.CookieFirst;
    resolve(consent && consent.performance);
  });

export const getCookieTrackingConsentNoPromise = (): boolean => {
  if (typeof window === 'undefined' || !window.CookieFirst) {
    return false;
  }
  const {consent} = window.CookieFirst;

  return consent && consent.performance;
};
