/**
 * List of Segment events described here:
 * https://tideaccount.atlassian.net/wiki/spaces/PCSFO/pages/4641292573/Product+Analytics+-+Event+Names
 */

export default {
  fundingCloud: {
    quoteForm: {
      financeInformation: {
        pageLoad: 'pcs_fc_finance_info_page_load',
        pageSubmit: 'pcs_fc_finance_info_page_submit',
        apiAccountCreateSuccess: 'pcs_fc_api_call_account_created',
        apiAccountCreateFailure: 'pcs_fc_api_call_acc_created_failure',
        apiApplicationCreateSuccess: 'pcs_fc_api_application_created',
        apiApplicationCreateFail: 'pcs_fc_api_call_app_created_fail',
        grossAssetCostChanged: 'pcs_fc_gross_asset_cost_changed',
        netAssetCostChanged: 'pcs_fc_net_asset_cost',
        totalNumberOfAssetsChanged: 'pcs_fc_total_number_of_assets',
        financePurposeChanged: 'pcs_fc_finance_purpose',
        assetTypeChanged: 'pcs_fc_asset_type',
        requestedAmountChanged: 'pcs_fc_requested_amount',
      },
      companyInformation: {
        pageLoad: 'pcs_fc_company_info_page_load',
        pageBack: 'pcs_fc_company_info_page_back',
        pageSubmit: 'pcs_fc_company_info_page_submit',
        apiApplicationPatchSuccess: 'pcs_fc_api_call_application_patched',
        apiApplicationPatchFail: 'pcs_fc_api_call_application_patch_fail',
        modalRequestAmountVsTurnoverOnLoad: 'pcs_fc_modal_rev_requested_amount_load',
        modalRequestAmountVsTurnoverSubmit: 'pcs_fc_modal_rev_requested_amount_submit',
        modalRequestAmountVsTurnoverValueChanged: 'pcs_fc_modal_rev_request_amnt_changed',
        numberOfDirectorsChanged: 'pcs_fc_number_of_directors',
        assetsWithinBusinessChanged: 'pcs_fc_net_assets_within_business',
        legalStatusChanged: 'pcs_fc_legal_status_changed',
      },
      directorsInformation: {
        addAddressButton: 'pcs_fc_directors_page_add_addr',
        addDirectorButton: 'pcs_fc_directors_page_add_director',
        apiAddressDeleteFail: 'pcs_fc_api_call_dir_addr_del_fail',
        apiAddressDeleteSuccess: 'pcs_fc_api_call_dir_addr_del_success',
        apiAddressPostFail: 'pcs_fc_api_call_dir_addr_post_fail',
        apiAddressPostSuccess: 'pcs_fc_api_call_dir_addr_post_success',
        apiDirectorDeleteFail: 'pcs_fc_api_call_director_delete_fail',
        apiDirectorDeleteSuccess: 'pcs_fc_api_call_director_del_success',
        apiDirectorPatchFail: 'pcs_fc_api_call_director_patch_fail',
        apiDirectorPatchSuccess: 'pcs_fc_api_call_dir_patch_success',
        apiDirectorPostFail: 'pcs_fc_api_call_director_post_fail',
        apiDirectorPostSuccess: 'pcs_fc_api_call_director_post_success',
        deleteAddressAction: 'pcs_fc_directors_page_del_addr_act',
        deleteAddressButton: 'pcs_fc_directors_page_del_addr',
        deleteDirectorButton: 'pcs_fc_directors_page_del_director',
        editAddressButton: 'pcs_fc_directors_page_edit_addr',
        editDirectorButton: 'pcs_fc_directors_page_edit_dir_button',
        pageLoad: 'pcs_fc_directors_page_load',
        pageSubmit: 'pcs_fc_directors_page_submit',
        directorsFormNavigateToNextPage: 'pcs_directors_form_navigate_to_next_pg',
        prefillDirectorsLoad: 'pcs_fc_directors_page_prefill_load',
        prefillDirectorsSelectButton: 'pcs_fc_dirs_page_prefill_dirs',
        saveAddressAction: 'pcs_fc_directors_page_save_address_act',
        saveAddressButton: 'pcs_fc_directors_page_save_addr',
        saveDirectorSubmit: 'pcs_fc_directors_page_save_dir_act',
        updateAddressAction: 'pcs_fc_directors_page_update_addr_act',
        upsertAddressButton: 'pcs_fc_directors_page_update_addr_btn',
        updateDirectorSubmit: 'pcs_fc_directors_page_update_dir_act',
        updateDirectorButton: 'pcs_fc_directors_page_update_dir_btn',
      },
      openAccounting: {
        quoteFormOpenAccountingLoad: 'pcs_quote_form_open_accounting_load',
        quoteFormOpenAccountingFailure: 'pcs_quote_form_open_accounting_failure',
      },
      openBanking: {
        obMandatoryLoad: 'pcs_fc_ob_docs_page_mandatory_load',
        obOptionalLoad: 'pcs_fc_ob_docs_page_under_25K_opt_load',
        seeResultsButton: 'pcs_fc_ob_docs_page_see_results_button',
        pageBack: 'pcs_fc_ob_docs_page_back',
        obButtonLoaded: 'pcs_fc_ob_docs_page_ob_button_load',
        obButton: 'pcs_fc_ob_docs_page_ob_button',
        obStatusLoaded: 'pcs_fc_ob_docs_page_status_load',
        obStart: 'pcs_fc_ob_docs_page_ob_start',
        manualUploadBankStatementsButton: 'pcs_fc_ob_page_manual_bank_statements',
        manualUploadFullFiledAccountsButton: 'pcs_fc_ob_doc_man_full_filed_acc_tap',
        matchedProductsPostSuccess: 'pcs_fc_matched_products_post_success',
        matchedProductsPostFail: 'pcs_fc_matched_products_post_fail',
        pageSkip: 'pcs_fc_ob_page_skip',
        pageNext: 'pcs_fc_ob_docs_page_see_results',
      },
      documentsUpload: {
        documentServicePostSuccess: 'pcs_fc_api_call_doc_svc_post_success',
        documentServicePostFail: 'pcs_fc_api_call_doc_svc_post_fail',
        documentServiceDeleteSuccess: 'pcs_fc_api_call_doc_svc_del_success',
        documentServiceDeleteFail: 'pcs_fc_api_call_doc_svc_del_fail',
        pageSubmit: 'pcs_fc_doc_scv_next',
        pageBack: 'pcs_fc_doc_scv_previous',
      },
      additionalQuestions: {
        pageLoad: 'pcs_fc_additional_questions_page_load',
        pageSkip: 'pcs_fc_additional_questions_page_skip',
        pageSubmit: 'pcs_fc_additional_questions_next_page',
        apiPatchSuccess: 'pcs_fc_additional_quest_patch_success',
        apiPatchFail: 'pcs_fc_additional_questions_patch_fail',
      },
    },
    account: {
      confirmAccount: 'pcs_confirm_account',
      confirmEmailSubmit: 'pcs_confirm_email_address_submit',
      createAccountModalLoad: 'pcs_create_account_modal_shown',
      createAccountSelectAdvisor: 'pcs_modal_create_advisor_account',
      createAccountSelectBusiness: 'pcs_modal_create_customer_account',
      createBusinessAccountSubmit: 'pcs_create_account_customer_submit',
      forgotPasswordSubmit: 'pcs_forgot_password',
      resetPasswordSubmit: 'pcs_reset_password',
      loginSubmit: 'pcs_login',
      loginPageOnLoad: 'pcs_login_in_page_on_load',
    },
    dashboard: {
      fpsp: {
        pageLoad: 'pcs_fc_fpsp_load',
        applyToQuote: 'pcs_fc_apply_quote',
        additionalQuestionsBannerLoad: 'pcs_fpsp_additional_quest_banner_load',
        additionalQuestionsCTA: 'pcs_fpsp_additiona_questions_cta_click',
        refreshButton: 'pcs_fc_fpsp_refresh_button',
        submitRequestButton: 'pcs_fpsp_submit_request_button',
        exploreQuoteButton: 'pcs_fpsp_explore_quote_button',
        exploreOfferButton: 'pcs_fpsp_explore_offer_button',
        backToResultsButton: 'pcs_fpsp_back_to_results_button',
        proceedWithQuoteButton: 'pcs_fpsp_proceed_quote_button',
        proceedWithOfferButton: 'pcs_fpsp_proceed_offer_button',
        matchesGetSuccess: 'pcs_api_call_matches_get_success',
        matchesGetFail: 'pcs_api_call_matches_get_fail',
        modalAplliedForContinueToLenderButton: 'pcs_applied_for_modal_continue',
        modalAppliedForDismiss: 'pcs_applied_for_modal_return',
        modalAppliedForProductInfoLoad: 'pcs_continue_with_lender',
        modalAppliedForContinuationUrlFailed: 'pcs_wait_for_continuation_url_Failed',
        modalAppliedForContinuationUrlStarted: 'pcs_wait_for_continuation_url_started',
        modalAppliedForContinuationUrlSuccess: 'pcs_wait_for_continuation_url_success',
      },
      myApplications: {
        pageLoad: 'pcs_fc_my_applications_load',
        apiSelectedProductSuccess: 'pcs_fc_api_customer_selec_prod_success',
        apiSelectedProductsFail: 'pcs_fc_api_customer_selec_prod_fail',
        completeApplicationButton: 'pcs_fc_complete_application_button',
      },
      documentUpload: {
        pageLoad: 'pcs_fc_doc_svc_load',
        bankStatementsButton: 'pcs_fc_doc_svc_manual_bank_sttmnt_btn',
        fullFiledAccountsButton: 'pcs_fc_doc_svc_manual_full_filed_btn',
        merchantAccountsButton: 'pcs_fc_doc_svc_manual_merchant_acc_btn',
        vatReturnsButton: 'pcs_fc_doc_svc_manual_vat_rtrn_btn',
        apiPostSuccess: 'pcs_fc_doc_svc_post_success',
        apiPostFail: 'pcs_fc_doc_svc_post_fail',
        apiDeleteSuccess: 'pcs_fc_doc_svc_delete_success',
        apiDeleteFail: 'pcs_fc_doc_svc_delete_fail',
      },
      advisor: {
        copyAffidValue: 'pcs_advisor_copy_affid_val',
        createAccountSubmit: 'pcs_advisor_create_account_submit',
        contactClient: 'pcs_advisor_fo_contact_client',
        getStarted: 'pcs_advisor_get_started',
        referModalOpen: 'pcs_advisor_refer_modal_open',
        selfContactClientClose: 'pcs_advisor_self_contact_client_close',
        shareApplicationButton: 'pcs_advisor_share_app_w_client_btn',
        shareApplicationModalLoad: 'pcs_advsr_shr_app_w_client_modal_load',
        shareApplicationSubmit: 'pcs_advisor_share_app_w_client_submit',
        viewClientApplication: 'pcs_advisor_view_client_application',
      },
    },
  },
  actions: {
    confirmOwnershipPageLoad: 'pcs_confirm_ownership_on_load',
    dismissibleDialogDismissed: 'pcs_dialogue_dismiss',
    redirectToTide: 'pcs_redirect_to_tide',
    modalMailingListSignupLoad: 'pcs_mailing_signup_modal_shown',
    modalMailingListSignupSubmit: 'pcs_mailing_signup_modal_submitted',
    modalCreateAccountShown: 'pcs_create_account_modal_shown',
    systemDataInfo: 'pcs_system_data',
    leadCreated: 'pcs_lead_created',
  },
  errors: {
    serverError: 'pcs_500_error',
    clientError: 'pcs_front_end_error',
  },
  ctas: {
    requestQuote: 'pcs_credit_overview_request_quote',
    newQuote: 'pcs_credit_overview_request_new_quote',
    overviewLoaded: 'pcs_credit_overview_loaded',
    getStarted: 'pcs_get_started',
    greenFinanceForSMEs: 'pcs_green_finance_for_smes',
  },
  pages: {
    bca: {
      productInfoButton: 'pcs_bca_product_button_info',
      productExternalInfoButton: 'pcs_bca_product_button_info_external',
    },
    blc: {
      newQuote: 'pcs_business_loan_calculator_cta',
      calculateButtonClicked: 'pcs_business_loans_calc_calculate',
    },
    blog: {
      subscribedToNewsletter: 'pcs_blog_subscribed_to_newsletter',
    },
    homePage: {
      businessServicesCta: 'pcs_business_services_cta',
    },
    greenFinance: {
      commitmentToESGCta: 'pcs_grn_finance_commitment_to_esg_cta',
      ctaForLenders: 'pcs_green_finance_for_lenders_cta',
      ctaForSMEs: 'pcs_green_finance_for_smes_cta',
      ctaHero: 'pcs_green_finance_hero_cta',
      ctaRelatedArticles: 'pcs_green_finance_related_articles_cta',
    },
    invoiceFinanceCalculator: {
      calculateButtonClicked: 'pcs_invoice_finance_calc_calculate',
    },
    assetFinanceCalculator: {
      calculateButtonClicked: 'pcs_asset_finance_calc_calculate',
    },
    merchantLoanCalculator: {
      calculateButtonClicked: 'pcs_merchant_loan_calc_calculate',
    },
    revolvingCreditFacilityCalculator: {
      calculateButtonClicked: 'pcs_rvlvng_cred_fclty_calc_calculate',
    },
    workWithUs: {
      formSubmitButtonClicked: 'pcs_wrk_w_us_form_submit_btn_clicked',
    },
  },
  navigation: {
    alternateJourneyLink: 'pcs_nav_alternate_journey_link_clicked',
    cardCTAButton: 'pcs_nav_card_link_clicked',
    link: 'pcs_nav_link_clicked',
    mainCTAButton: 'pcs_nav_main_cta_clicked',
    loginButton: 'pcs_credentials_login',
    logoutButton: 'pcs_credentials_logout',
    signupButton: 'pcs_credentials_signup',
    accountDetailsButton: 'pcs_user_menu_click_account_details',
    dashboardButton: 'pcs_user_menu_click_dashboard',
    newQuoteButton: 'pcs_start_new_quote',
    userMenu: {
      dashboard: 'pcs_nav_user_menu_dashboard_click',
      accountDetails: 'pcs_nav_user_menu_account_dtls_click',
    },
  },
};
