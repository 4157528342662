import {
  MixpanelTrackEvent,
  dispatchMixpanelEvent,
  dispatchMixpanelSuperEvent,
  ITrackEvent as IMixpanelTrackEvent,
  enqueueMixpanelEvent,
} from './mixpanel';
import {GTMTrackEvent, dispatchGAEvent, ITrackEvent as IGTMTrackEvent} from './googleAnalytics';
import {HotjarTrackEvent, dispatchHotjarEvent} from './hotjar';
import {dispatchFoEvent, FoTrackEvent, IDispatchEvent as IFoDispatchEvent} from './fo';

export {
  MixpanelTrackEvent,
  dispatchMixpanelSuperEvent,
  enqueueMixpanelEvent,
  GTMTrackEvent,
  HotjarTrackEvent,
  FoTrackEvent,
};

export enum EventType {
  GTM = 'gtm',
  MIXPANEL = 'mixpanel',
  HOTJAR = 'hotjar',
  FO = 'fo',
}
export interface TrackEvent {
  name: MixpanelTrackEvent | GTMTrackEvent | HotjarTrackEvent | FoTrackEvent;
  values?: ObjectOfAny;
  eventType: EventType;
}

export const trackEvent = (event: TrackEvent): Promise<void> => {
  const {eventType} = event;

  if (eventType === EventType.GTM) {
    return new Promise((resolve) => {
      dispatchGAEvent(event as IGTMTrackEvent);
      resolve();
    });
  }
  if (eventType === EventType.MIXPANEL) {
    return dispatchMixpanelEvent(event as IMixpanelTrackEvent);
  }
  if (eventType === EventType.HOTJAR) {
    return new Promise((resolve) => {
      dispatchHotjarEvent(event.name as HotjarTrackEvent);
      resolve();
    });
  }
  if (eventType === EventType.FO) {
    return dispatchFoEvent(event as IFoDispatchEvent);
  }

  return new Promise((resolve) => resolve);
};
export default {trackEvent};
