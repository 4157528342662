import {isBrowser} from '@fo/shared/utils/browserSupport';

export enum GTMTrackEvent {
  calculatorsV2AssetFinance = 'asset_finance_calculator',
  calculatorsV2BusinessLoans = 'business_loan_calculator',
  calculatorsV2InvoiceFinance = 'invoice_finance_calculator',
  calculatorsV2MerchantLoan = 'merchant_loan_calculator',
  calculatorsV2RevolvingCredit = 'revolving_credit_calculator',
  calculatorsV2RedirectToTide = 'calculators_redirect_to_tide',
  businessLoanCalculatorCalculate = 'loan_calculator',
  directorsFormOnLoad = 'directors_form_on_load',
  directorsFormNavigateToNextPage = 'directors_form_navigate_to_next_page',
  fpspOnLoad = 'fpsp_on_load',
  pageLoad = 'page_load',
  phoenixLeadCreated = 'phoenixLeadCreated',
  phoenixSubmit = 'phoenixSubmit',
  quoteFormCta = 'get_started',
  quoteFormOnLoad = 'quote_form_on_load',
  quoteFormStepFour = 'quote_form_step_four',
  quoteFormStepOne = 'quote_form_step_one',
  quoteFormStepTwo = 'quote_form_step_two',
  quoteFormOpenBankingManualUpload = 'quote_form_open_banking_manual_upload',
  systemData = 'system_data',
}

export interface ITrackEvent {
  name: GTMTrackEvent;
  values?: ObjectOfAny;
}

export const dispatchGAEvent = ({name, values = {}}: ITrackEvent): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: name.toString(),
    ...values,
    ...(isBrowser && window.FOID ? {user_id: window.FOID} : {}),
  });
};
